<template>
  <v-app class="admin-app-layout">
    <app-header />
    <app-drawer />

    <v-main>
      <slot />
    </v-main>

    <order-action-dialog />
    <global-snackbar />
    <global-email-viewer />
    <global-pin />
    <dialog-view-email-detail />
  </v-app>
</template>

<script>
import AppDrawer from '@/components/app-drawer.vue'
import AppHeader from '@/components/app-header.vue'
import DialogViewEmailDetail from '@/components/admin/emails/dialog-view-email-detail.vue'
import GlobalEmailViewer from '@/components/admin/global-email-viewer.vue'
import GlobalPin from '@/components/admin/global-pin.vue'
import GlobalSnackbar from '@/components/global-snackbar.vue'
import OrderActionDialog from '@/components/admin/order-action-dialog.vue'

export default {
  name: 'admin-app-layout',
  components: {
    AppDrawer,
    AppHeader,
    DialogViewEmailDetail,
    GlobalEmailViewer,
    GlobalPin,
    GlobalSnackbar,
    OrderActionDialog,
  },
}
</script>
