<template>
  <div
    v-if="globalEmailViewer && email && showSnackbar"
    ref="el"
    class="global-email-viewer elevation-6"
    data-test="global-email-viewer"
    :style="`${style}; z-index: ${zIndex}`"
  >
    <div class="move-me" />
    <div v-if="minimized" class="text-black text-caption">
      <div><span class="text-subtitle-2">Subject: </span>{{ email.subject }}</div>
    </div>
    <div v-else class="email-container text-caption">
      <div><span class="text-subtitle-2">From: </span>{{ email.from_address }}</div>
      <div><span class="text-subtitle-2">To: </span>{{ email.to_addresses }}</div>
      <div><span class="text-subtitle-2">Subject: </span>{{ email.subject }}</div>
      <safe-html-renderer :html="email.body" />
    </div>

    <v-btn-toggle
      borderless
      bg-color="transparent"
      class="left-actions-btn-toggle"
      active-class="actions-btn-toggle-active"
    >
      <v-tooltip v-if="showOrderBtn" location="bottom" :z-index="zIndex+1">
        <template v-slot:activator="{ props }">
          <qtm-icon-btn icon="mdi-receipt" v-bind="props" size="x-small" @click="showOrder" />
        </template>
        <span>Open related order</span>
      </v-tooltip>

      <v-tooltip location="bottom" :z-index="zIndex+1">
        <template v-slot:activator="{ props }">
          <qtm-icon-btn icon="mdi-email" v-bind="props" size="x-small" @click="showEmail" />
        </template>
        <span>Open Email</span>
      </v-tooltip>
    </v-btn-toggle>

    <v-btn-toggle
      borderless
      bg-color="transparent"
      class="actions-btn-toggle"
      active-class="actions-btn-toggle-active"
    >
      <v-tooltip location="bottom" :z-index="zIndex+1">
        <template v-slot:activator="{ props }">
          <qtm-icon-btn v-bind="props" size="x-small" :icon="toggleIconMinimized" @click="toggleMinimized" />
        </template>
        <span>{{ minimizedTooltip }}</span>
      </v-tooltip>

      <v-tooltip location="bottom" :z-index="zIndex+1">
        <template v-slot:activator="{ props }">
          <qtm-icon-btn v-bind="props" size="x-small" icon="mdi-close" @click="hideGlobalEmailViewer" />
        </template>
        <span>Close window</span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>

<script setup lang="ts">
import { useDraggable } from '@vueuse/core'
import SafeHtmlRenderer from '@/components/admin/emails/safe-html-renderer.vue'

const adminStore = useAdminStore()

const el = ref<HTMLElement | null>(null)

const { style } = useDraggable(el, {
  initialValue: { x: 40, y: 40 },
})

const showSnackbar = ref(false)
const minimized = ref(false)
const zIndex = ref(1)

const recalculateZIndex = async () => {
  await nextTick()
  document.getElementsByClassName('v-dialog').forEach(element => {
    zIndex.value = 1 + Math.max(zIndex.value, parseInt(element.style.zIndex, 10))
  })
}

onMounted(recalculateZIndex)

const globalEmailViewer = computed(() => adminStore.globalEmailViewer)
const email = computed(() => globalEmailViewer.value?.email)

const minimizedTooltip = computed(() => {
  return minimized.value ? 'Restore window' : 'Minimize window'
})
const showOrderBtn = computed(() => {
  return !!email.value.rfq
})
const toggleIconMinimized = computed(() => {
  return minimized.value ? 'mdi-window-restore' : 'mdi-window-minimize'
})

watch(globalEmailViewer, () => {
  showSnackbar.value = globalEmailViewer.value?.show
  recalculateZIndex()
})
const hideGlobalEmailViewer = () => {
  adminStore.hideGlobalEmailViewer()
}
const activateEmail = (emailToActivate) => {
  adminStore.activateEmail(emailToActivate)
  recalculateZIndex()
}
const activateOrder = (order) => {
  adminStore.activateOrder(order)
  recalculateZIndex()
}
const showEmail = () => {
  activateEmail(email.value)
}
const showOrder = () => {
  if (email.value && email.value.rfq) {
    activateOrder({ id: email.value.rfq })
  }
}
const toggleMinimized = () => {
  minimized.value = !minimized.value
}
</script>

<style lang="scss" scoped>
.actions-btn-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0;
}
.actions-btn-toggle-active::before {
  opacity: 0;
}
.actions-btn-toggle button, .left-actions-btn-toggle button {
  color: rgb(var(--v-theme-interactive));
  height: 24px !important;
}
.left-actions-btn-toggle {
  position: absolute;
  top: 0px;
  left: 5px;
  margin: 0;
}
.email-body {
  margin-right: -15px;
  max-height: 400px;
  overflow-y: auto;
}
.email-container {
  color: black;
  max-height: 400px;
  max-width: 400px;
  padding-bottom: 5px;
  overflow-y: auto;
}
.global-email-viewer {
  background-color: white;
  padding: 10px;
  position: fixed;
}
.move-me {
  cursor: move;
  height: 22px;
  width: 100%;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
}
</style>
